import React from 'react'
import axios from 'axios'
import { get } from 'http';

const ourstylesub = {
  color: "black",
  backgroundColor: "transparent",
  border: "2px solid transparent",
  fontSize: "15px",
  borderBottom: "1px solid white",
  paddingBottom: "13px",
  outline: "none",
  boxShadow: "none",
  cursor: "default",
  width: "100%"
};


const textcheckwhitesub = {
  color: "black",
  fontSize: "10px",
  border: "1px solid transparent",
};


const btnstyletextsub = { 
  color: "#54AA6F",
  fontWeight: "900",
  fontSize: "15px",
  width: "75%",
  overflow: "auto",
  textAlign: "center",
  boxShadow: 'none',
  maxHeight: "50px",
};

class Emailsub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TOS: false,
      email: ""
    };
    
    this.handleTOSsub = this.handleTOSsub.bind(this);
    this.handleEmailsub = this.handleEmailsub.bind(this);
    this.handleSubmitsub = this.handleSubmitsub.bind(this);
  }

  handleEmailsub(event) {
    if (event.target.value.match(/^([\w.%+-]+)@([\w-]+.)+([\w]{2,})$/i)) {
      this.setState({email: event.target.value})
    }
  }

  handleTOSsub(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    console.log(value)
    this.setState({
      TOS: value
    })

    if (this.state.TOS == true) {
      document.getElementById("TOSFAULT1").style.display = "block";
    }
    else {
      document.getElementById("TOSFAULT1").style.display = "none";
    }
  }

  handleSubmitsub(event) {

    event.preventDefault();
    const {
      email,
      TOS
    } = this.state

    if (this.state.TOS == false) {
      document.getElementById("TOSFAULT1").style.display = "block";
    }
    else {
      document.getElementById("TOSFAULT1").style.display = "none";
    }

    if (this.state.email == "") {
      document.getElementById("EMAILPR1").style.display = "block";
    }
    else {
      document.getElementById("EMAILPR1").style.display = "none";
    }

    setTimeout(function() {
      $('#thanks').fadeOut('fast');
  }, 3000); 
    console.log("Submit was pressed")
    
    axios.post("/subscribe", {
        subscribe: {
          email: email,
          TOS: TOS
        }            
    },
    { withCredentials: true }
    )
    .then(response => {
      console.log("Thanks for the sub", response);
      document.getElementById("THANKS1").style.display = "block";
      document.getElementById("EMAILEX1").style.display = "none";
    })
    .catch(error => {
      console.log("Registration error", error);
      if (this.state.TOS == true && this.state.email != "" ) {
      document.getElementById("EMAILEX1").style.display = "block";
      document.getElementById("THANKS").style.display = "none";
      }
    })
  }
  
  render () {
    return (
      <div className="col-sm-4 col-md-6 col-lg-12 col-xl-12">
      <form onSubmit={this.handleSubmitsub}>
       
      <input style={ourstylesub}
        id="email"
        type='email'
        className="form-control col-9"
        placeholder='Email Address'
        onChange={this.handleEmailsub}
      />
      <br></br>

      <input
        id="check"
        className="ml-0 mt-2 form-check-input"
        name="TOS"
        type='checkbox'
        onChange={this.handleTOSsub}
        style={{outline: "1.5px solid transparent"}}
      />
      <label id="TOS" style={textcheckwhitesub} className="ml-5 mt-1">
      By subscribing you are agreeing to our TOS
      </label>

      <br></br>

      <button id="btn" type="submit" value="submit" className="btn btn-white" style={btnstyletextsub}>
        Subscribe
      </button>
    
      <br></br>
      
      <p id="EMAILEX1" style={{display: "none"}}> This email has already subscribed </p>
      <p id="EMAILPR1" style={{display: "none"}}> You need to provide an email in order to subscribe </p>
      <p id="TOSFAULT1" style={{display: "none"}}> You need to agree to our TOS in order to subscribe</p>
      <p id="THANKS1" style={{display: "none"}}> Thanks for subscribing </p>
      <br></br>
      
      </form>
    </div>
    );
  }
}
export default Emailsub

