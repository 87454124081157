import React from 'react'
import axios from 'axios'
import { get } from 'http';

const ourstyle = {
  color: "black",
  backgroundColor: "transparent",
  border: "2px solid transparent",
  fontSize: "15px",
  borderBottom: "1px solid white",
  paddingBottom: "13px",
  outline: "none",
  boxShadow: "none",
  cursor: "default",
  width: "100%"
};

const textcheck = {
  color: "black",
  fontSize: "8px",
  border: "1px solid transparent"
};

const textcheckwhite = {
  color: "black",
  fontSize: "10px",
  border: "1px solid transparent",
};

const customthanks = {
  color: "white",
  fontSize: "15px",
  border: "none"
};

const marginator = {
  margin: 5
};

const errortext = {
  color: "black",
  fontSize: "11px",
  border: "1px solid transparent"
};

const btnstyletext = { 
  color: "#54AA6F",
  fontWeight: "900",
  fontSize: "15px",
  width: "75%",
  overflow: "auto",
  textAlign: "center",
  boxShadow: 'none',
  maxHeight: "50px",
};

class Checkoutform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: ""
    };
    
    this.handleAmount = this.handleAmount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleAmount(event) {
      this.setState({amount: event.target.value})
  }


  handleSubmit(event) {

    event.preventDefault();
    const {
      amount
    } = this.state

    if (this.state.amount == "") {
      document.getElementById("EMAILPR").style.display = "block";
    }
    else {
      document.getElementById("EMAILPR").style.display = "none";
    }
    setTimeout(function() {
      $('#thanks').fadeOut('fast');
  }, 3000); 
    console.log("Submit was pressed")
    
    axios.post("/paymentinfo", {
        voting: {
          amount: amount
        }            
    },
    { withCredentials: true }
    )
    .then(response => {
      console.log("Thanks for the amount", response);
      document.getElementById("THANKS").style.display = "block";
      document.getElementById("EMAILEX").style.display = "none";
    })
    .catch(error => {
      console.log("Registration error", error);
      if (this.state.amount = "" ) {
      document.getElementById("EMAILEX").style.display = "block";
      document.getElementById("THANKS").style.display = "none";
      }
    })
  }
  
  render () {
    return (
      <div className="col-sm-4 col-md-6 col-lg-12 col-xl-12">
      <form onSubmit={this.handleSubmit}>
       
      <input style={ourstyle}
        id="emailIDf"
        type=''
        className="form-control col-9"
        placeholder='$$$'
        onChange={this.handleAmount}
      />
      <br></br>

      <br></br>

      <button id="btnsub" type="submit" value="submit" className="btn btn-white" style={btnstyletext}>
        Donate now
      </button>
    
      <br></br>

      <p id="EMAILPR" style={{display: "none"}}> You need to provide an amount in order to donate </p>
      <p id="THANKS" style={{display: "none"}}> Thanks for providing an amount </p>
      <br></br>
      
      </form>
    </div>
    );
  }
}
export default Checkoutform
