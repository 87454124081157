import React from "react"
import PropTypes from "prop-types"
class Blog extends React.Component {

  render () {
    return (
      <div>
        <h1>{this.props.title}</h1>
        <p>{this.props.text}</p>
      </div>
    );
  }
}

export default Blog
